@use '@angular/material' as mat;

@import 'material-app-palettes';

@include mat.all-component-typographies;
@include mat.core;

/* stylelint-disable-next-line scss/dollar-variable-colon-newline-after */
$app-theme: mat.define-light-theme((
  color: (
    primary: mat.define-palette($it-blue, 100, 50, 200),
    accent: mat.define-palette($it-blue, 100, 50, 200),
    warn: mat.define-palette(mat.$red-palette),
  ),
  typography: mat.define-typography-config(),
  density: 0,
));

@include mat.all-component-themes($app-theme);
@include mat.slide-toggle-density(-1);
