.search-bar-form {
  display: flex;
  gap: 12px;
  margin-top: 30px;

  > * {
    width: fit-content;
  }

  &.no-margin {
    margin-top: 0;
  }
}
