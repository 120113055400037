/**
 * ITDS Spacing
 * @source https://www.figma.com/design/7CHWhlRnk29dfZHcME3G0O/%F0%9F%8E%A8-Admin-Components---Interticket-Design-System?node-id=1339-91728&vars=1&m=dev
 */
:root {
  --itds-spacing-0: 0px;
  --itds-spacing-2: 2px;
  --itds-spacing-4: 4px;
  --itds-spacing-6: 6px;
  --itds-spacing-8: 8px;
  --itds-spacing-10: 10px;
  --itds-spacing-12: 12px;
  --itds-spacing-14: 14px;
  --itds-spacing-16: 16px;
  --itds-spacing-20: 20px;
  --itds-spacing-24: 24px;
  --itds-spacing-32: 32px;
  --itds-spacing-40: 40px;
  --itds-spacing-48: 48px;
  --itds-spacing-56: 56px;
  --itds-spacing-64: 64px;
  --itds-spacing-72: 72px;
  --itds-spacing-80: 80px;
}
