$gutter-small: 0.75;
$gutter-submedium: 1.125;
$gutter-medium: 1.5;
$header-border-size: 1px;

:root {
  --gutter-size: #{$gutter-medium}rem;
  --gutter-size-negative: -#{$gutter-medium}rem;
  --header-border-size: #{$header-border-size};
  --content-width: #{$xlarge};

  @media screen and (max-width: $submedium) {
    --gutter-size: #{$gutter-submedium}rem;
    --gutter-size-negative: -#{$gutter-submedium}rem;
    --page-content-margin: var(--page-content-margin-mobile);
  }

  @media screen and (max-width: $small) {
    --gutter-size: #{$gutter-small}rem;
    --gutter-size-negative: -#{$gutter-small}rem;
  }
}
