@import 'variables/palettes';
@import 'customcolors';
@import 'breakpoints';

.owl-dt-inline,
.owl-dt-popup {
  --ui-kit-date-time-picker-primary-color: #{$cyan};
  --ui-kit-date-time-picker-primary-color-light: #{$cyan};
  --ui-kit-date-time-picker-primary-color-dark: #{$cyan};
  --ui-kit-date-time-picker-primary-color-background: #{$cyan};
  --ui-kit-date-time-picker-timer-input-color: var(--grey-color-dark-1);
  --ui-kit-date-time-picker-timer-input-border-color: var(--bluish-color-light-grey-2);

  @each $palette in $palettes {
    &.ui-kit-palette-#{$palette} {
      --ui-kit-date-time-picker-primary-color: var(--#{$palette}-color);
      --ui-kit-date-time-picker-primary-color-light: var(--#{$palette}-color-hover);
      --ui-kit-date-time-picker-primary-color-dark: var(--#{$palette}-color-active);
      --ui-kit-date-time-picker-primary-color-background: var(--#{$palette}-color-background);
    }
  }

  &.ui-kit-no-shadow {
    .owl-dt-container {
      box-shadow: none;
    }
  }

  .owl-dt-container {
    .owl-dt-calendar-table {
      border: none;

      .owl-dt-calendar-cell-selected {
        background-color: var(--ui-kit-date-time-picker-primary-color);
        box-shadow: 0 0 10px rgba(183 119 176 / 40%);
        color: white;

        &.owl-dt-calendar-cell-today {
          box-shadow: inset 0 0 0 1px var(--ui-kit-date-time-picker-primary-color), inset 0 0 0 2px var(--ui-kit-date-time-picker-primary-color-background);
        }

        &.day-with-timeslots {
          border: solid 2px var(--ui-kit-date-time-picker-primary-color-dark);
        }
      }

      .owl-dt-calendar-cell-today {
        &:not(.owl-dt-calendar-cell-selected) {
          background-color: initial;
          border: solid 1px var(--ui-kit-date-time-picker-primary-color);
        }
      }

      .owl-dt-calendar-cell-active {
        .owl-dt-calendar-cell-content {
          &.owl-dt-calendar-cell-today {
            box-shadow: inset 0 0 0 1px var(--ui-kit-date-time-picker-primary-color), inset 0 0 0 2px var(--ui-kit-date-time-picker-primary-color-background);
          }
        }
      }

      .owl-dt-calendar-cell {
        .day-with-timeslots {
          background-color: var(--ui-kit-date-time-picker-primary-color-light);
          color: white;
        }
      }

      .owl-dt-calendar-cell-disabled {
        .owl-dt-calendar-cell-today {
          border-color: rgba(0 0 0 / 40%);
        }
      }
    }

    .owl-dt-timer-content {
      .owl-dt-timer-input {
        color: var(--ui-kit-date-time-picker-timer-input-color);
        background: white;
        border: 1px solid var(--ui-kit-date-time-picker-timer-input-border-color);
      }
    }

    .owl-dt-timer-divider {
      &::before,
      &::after {
        background: var(--ui-kit-date-time-picker-primary-color);
      }

      &::before {
        top: 40%;
      }

      &::after {
        bottom: 40%;
      }
    }

    .owl-dt-container-info {
      .owl-dt-container-info-active {
        color: var(--ui-kit-date-time-picker-primary-color);
      }
    }

    .owl-dt-container-buttons {
      color: var(--ui-kit-date-time-picker-primary-color);
    }
  }
}

/*******************
 * FULL SCREEN MODE
 *******************/
@media screen and (max-width: $submedium) {
  .ui-kit-custom-range-picker-backdrop {
    + .cdk-overlay-connected-position-bounding-box {
      top: 0 !important;
      bottom: 0 !important;
      right: 0 !important;
      /* stylelint-disable-next-line declaration-block-no-redundant-longhand-properties */
      left: 0 !important;
      height: 100% !important;
      width: 100% !important;

      .filter-dropdown-panel {
        position: absolute;
        inset: 0;
        max-width: 100%;
        height: 100%;
        margin: 0;
        border-radius: 0;

        .mat-mdc-menu-content,
        .mat-mdc-menu-content > div,
        .filter-dropdown-content,
        .filter-dropdown-date-picker,
        owl-date-time-calendar {
          width: 100%;
          height: 100%;
        }

        owl-date-time-inline,
        owl-date-time-container {
          width: 100%;
        }

        .mat-mdc-menu-content {
          padding: 0;
        }

        .filter-dropdown-date-picker {
          margin: 0 !important;
        }

        .filter-dropdown-content {
          margin: 0 !important;
        }

        owl-date-time-inline {
          padding-top: 5px;
        }

        ui-kit-date-time-actions {
          position: absolute;
          z-index: 1001;
          bottom: 0;
          right: 0;
          left: 0;
        }
      }
    }
  }
}
