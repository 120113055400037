@import 'breakpoints';

.toast-container {
  pointer-events: none;
  position: fixed;
  z-index: 999999;

  * {
    box-sizing: border-box;
  }

  &.toast-top-right {
    top: 12px;
    right: 12px;
  }

  @media screen and (max-width: $small) {
    left: 12px;

    .ngx-toastr {
      width: 100%;
    }
  }
}
