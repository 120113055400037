// custom colors
$white: #fff;
$bright-red: #d9534f;
$bright-green: #5cb85c;
$red: #f00000;
$matt-red: #ce5749;
$gray: #bebebe;
$light-gray: #eee;
$lighter-gray: #fafafa;
$darkish-gray: #dcdcdc;
$medium-gray: #999;
$dark-gray: #222;
$black: #000;
$cyan: #39b4e3;
$blue: #4267b2;
$red-light: #d34836;
$red-dark: #980000;
$light-blue: #007fff;
$lighter-red: #f2655a;
$green: #50c878;
$darkish-green: #319b54;
$light-green: #12c782;
$orange: #ff922e;

// status color
$status-green: #50c878;
$status-orange: #f5a623;
$status-brown: #8a817c;
$status-black: #2d383a;
$status-red: #f2655a;
$status-blue: #007fff;

// boxoffice cart
$boxoffice-cart-border: #007fff;
$boxoffice-cart-active-bg: #b2d8ff;

// boxoffice timeslot
$boxoffice-timeslot-container-bg: #f2f2f2;
$boxoffice-timeslot-button: #7b7c90;
$boxoffice-timeslot-button-bg-hightlighted: #cdcdd6;
$boxoffice-timeslot-button-point: #764ff6;

// base colors
$secondary-color: #4a4a4a;
$action-primary-color: #007fff;
$action-primary-hover-color: #b2d8ff;
$edit-primary-color: #2d383a;
$edit-primary-hover-color: #c0c3c3;
$delete-primary-color: #f2655a;
$delete-primary-hover-color: #fbd0cd;
$face-primary-color: #50c878;
$face-primary-hover-color: #c7eed4;
$kick-primary-color: $edit-primary-color;
$kick-primary-hover-color: $edit-primary-hover-color;
$email-primary-color: #949494;
$email-primary-hover-color: #eaeaea;

// backgrounds
$body-background: #fff;

// boxes
$box-bg: #f0f0f0;
$box-border: #e6e6e6;
$box-hr: #ddd;

// selects
$select-border: #4a4a4a;
$select-hover: #dadada;

// inputs
$input-focus: #92d6f0;

// table
$table-border: #dedede;
$table-row-hover: #dadada;
$table-thead-bg: #d7ecfa;
$table-thead-border: #cadeeb;
$table-row-odd-bg: #eaeaea;
$table-row-even-bg: #f5f5f5;
$table-row-bg-even: rgba(#000, 0.06);
$table-row-bg-hover: rgba(#000, 0.08);
$table-row-bg-sort: rgba(#000, 0.04);
$table-row-bg-even-sort: rgba(#000, 0.04);
$table-row-bg-sort-hover: rgba(#000, 0.04);
$table-row-bg-even-sort-hover: rgba(#000, 0.06);
$table-row-deleted: #ffdbd6;

// fonts
$primary-font-color: #333;
$secondary-font-color: #777;
