@import 'customcolors';

.mat-mdc-select {
  transition: background-color 0.25s ease-in-out;

  &.custom-select {
    height: 36px;
    font-size: 0.8125rem;
    font-weight: normal;
    line-height: 1.6;
    margin-bottom: 16px;
    border: 1px solid $select-border;
    background-color: var(--background-color);
    box-shadow: inset 0 1px 2px rgb(0 0 0 / 10%);
    transition: box-shadow 0.5s ease-in-out;

    .mat-mdc-select-trigger {
      height: 100%;

      .mat-mdc-select-value {
        height: 2.2375rem;
        padding: 0;
        line-height: 2.2375rem;

        .mat-mdc-select-value-text {
          padding: 0.5rem;
        }

        .mat-mdc-select-placeholder {
          padding: 0.5rem;
          color: var(--placeholder-text-color);
        }
      }

      .mat-mdc-select-arrow-wrapper {
        padding: 8px;
      }
    }
  }

  &.venue-select {
    font-family: inherit;

    &.mat-mdc-select-disabled {
      .mat-mdc-select-value {
        cursor: not-allowed;

        &::after,
        .mat-mdc-select-placeholder,
        .mat-mdc-select-value-text {
          opacity: 0.75;
        }
      }
    }

    .mat-mdc-select-trigger .mat-mdc-select-value {
      &::after {
        content: '';
        display: inline-block;
        width: 10px;
        height: 10px;
        margin-bottom: 5px;
        border-right: 2px solid var(--primary-color);
        border-bottom: 2px solid var(--primary-color);
        transform: rotate(45deg);
      }

      .mat-mdc-select-placeholder,
      .mat-mdc-select-value-text {
        padding-right: 15px;
        color: var(--bluish-color-dark-grey-1);
        font-size: 20px;
        font-weight: 500;
      }
    }

    .mat-mdc-select-arrow {
      color: transparent;
    }
  }

  &.mat-mdc-select-disabled {
    background-color: $light-gray;
    cursor: not-allowed;
  }
}

.cdk-overlay-pane .mdc-menu-surface.mat-mdc-select-panel {
  padding: 0;
  border: 1px solid $light-gray;
  background-color: $white;
  box-shadow: none !important;

  &.venue-select-panel {
    border-radius: 12px;
    padding: 10px;

    .mat-mdc-option {
      padding: 0 10px;
      border-radius: 8px;
      color: var(--primary-color);
      font-size: 14px;
      font-weight: 500;

      &:not(:last-of-type) {
        margin-bottom: 5px;
      }
    }
  }

  .mat-mdc-option {
    min-height: 0;
    height: 39px;
    color: $secondary-color;
    font-size: 13px;

    &-pseudo-checkbox {
      display: none;
    }

    &.mdc-list-item--selected .mdc-list-item__primary-text {
      color: $secondary-color;
    }

    &:hover {
      background-color: $select-hover !important;

      .mdc-list-item__primary-text {
        color: $cyan;
      }
    }

    .mat-pseudo-checkbox-checked::after {
      top: 2px;
      width: 10px;
      height: 5px;
      border-width: 1px;
    }
  }
}
