// Block
.d-block {
  display: block;
}

// Display none
.d-none {
  display: none;
}

// Display inline flex
.d-inline-flex {
  display: inline-flex;
}

// Display flex
.d-flex {
  display: flex;
}

// Wrap
.flex-wrap {
  display: flex;
  flex-wrap: wrap;
}

// Horizontal align
.flex-left {
  display: flex;
  justify-content: flex-start;
}

.flex-end {
  display: flex;
  justify-content: flex-end;
}

.flex-center {
  display: flex;
  justify-content: center;
}

.flex-justify {
  display: flex;
  justify-content: space-between;
}

.flex-spaced {
  display: flex;
  justify-content: space-around;
}

// Vertical align
.flex-top {
  display: flex;
  align-items: flex-start;
}

.flex-middle {
  display: flex;
  align-items: center;
}

.flex-bottom {
  display: flex;
  align-items: flex-end;
}

.flex-stretch {
  display: flex;
  align-items: stretch;
}

// Align self
.flex-self-top {
  display: flex;
  align-self: flex-start;
}

.flex-self-middle {
  display: flex;
  align-self: center;
}

.flex-self-bottom {
  display: flex;
  align-self: flex-end;
}

.flex-self-stretch {
  display: flex;
  align-self: stretch;
}

// Center Middle
.flex-center-middle {
  display: flex;
  align-items: center;
  justify-content: center;
}

// Inline Flex Middle
.inline-center-middle {
  @extend .d-inline-flex;

  align-items: center;
  justify-content: center;
}

.flex-space-between {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.flex-column {
  display: flex;
  flex-direction: column;
}

.flex-fill-container {
  display: flex;
  min-height: 100%;
  flex: 1 1 100%;
  flex-flow: column;
}

.flex-fill-container-fix {
  display: flex;
  min-height: 100%;
  max-height: 100%;
  flex: 1 1 100%;
  flex-flow: column;
}

// Grow
.flex-grow {
  flex-grow: 1;
}
