@import 'mixins/typography';

mat-tab-header {
  border-bottom: 1px solid rgb(0 0 0 / 12%);
}

.mat-mdc-tab-group {
  &.mat-mdc-tab-group-stretch-tabs {
    > .mat-mdc-tab-header {
      .mat-mdc-tab {
        @include font-family-open-sans;

        flex-grow: var(--mat-mdc-tab-grow, 0) !important;
        font-size: 14px;
        font-weight: 500;
        letter-spacing: normal;
      }
    }
  }
}
