
@import '@interticket/customer-facing-ui-kit/styles/colors';

:root {
  /***********************
   * MAIN PALETTE
   ***********************/

  // Default
  --default-color: white;
  --default-color-hover: #eff8fc;
  --default-color-active: white;

  // Primary
  --primary-color: #3d3e5a;
  --primary-color-hover: #64657b;
  --primary-color-active: #151738;
  --primary-color-background: #e8e8ee;
  --primary-color-contrast: white;

  // Primary light
  --primary-light-color-hover: var(--primary-color);
  --primary-light-color-hover-background: #afafba;
  --primary-light-color-active-background: #7b7c90;

  // Secondary
  --secondary-color: #f0f0f5;
  --secondary-color-hover: #f7f7fa;
  --secondary-color-active: #afafba;

  // Success
  --success-color: #359570;
  --success-color-hover: #41b186;
  --success-color-active: #1c7a56;
  --success-color-background: #beecda;

  // Warning
  --warning-color: #ffc107;
  --warning-color-hover: #ffc107;
  --warning-color-active: #ffc107;
  --warning-color-light: #f8f2e2;

  // Error
  --error-color: #ba181b;
  --error-color-hover: #eec5c6;
  --error-color-active: #8c0f12;
  --error-color-background: #f8e8e8;
  --error-color-light: #f4e8e8;

  // Info
  --info-color: #2680ff;
  --info-color-hover: #2680ff;
  --info-color-active: #2680ff;

  // Action
  --action-color: #7b7c90;
  --action-color-hover: #55566e;
  --action-color-active: #3d3e5a;

  // Purple
  --purple-color: #8f99f0;
  --purple-color-hover: #a5adf3;
  --purple-color-active: #764ff6;
  --purple-color-background: #f5f6fe;

  // Orange
  --orange-color: #de9582;
  --orange-color-hover: #e5aa9b;
  --orange-color-active: #d28975;
  --orange-color-background: #fcf4f3;

  // Pink
  --pink-color: #b7779e;
  --pink-color-hover: #c592b1;
  --pink-color-active: #b1598f;
  --pink-color-background: #f8f1f7;

  // Blue
  --blue-color: #5db3de;
  --blue-color-hover: #51a0c7;
  --blue-color-active: #3c83a7;
  --blue-color-background: #e9f2ff;

  // Green
  --green-color: #458aad;
  --green-color-hover: #6aaecf;
  --green-color-active: #146a86;

  // PurpleBlue
  --purple-blue-color: #8c8dab;
  --purple-blue-color-hover: #73759a;
  --purple-blue-color-active: #4f506f;
  --purple-blue-color-background: #e2e4f3;

  /***********************
   * TEXT
   ***********************/
  --primary-text-color: #4b4b4b;
  --secondary-text-color: #7a7a7a;
  --placeholder-text-color: #aeaeae;

  /***********************
   * ACCENT
   ***********************/
  --accent-color-very-dark-blue: #2c5366;

  /***********************
   * BLUISH SHADES
   ***********************/
  --bluish-color-black: #151738;
  --bluish-color-dark-grey-1: #3d3e5a;
  --bluish-color-dark-grey-2: #7b7c90;
  --bluish-color-light-grey-1: #afafba;
  --bluish-color-light-grey-2: #e8e8ee;
  --bluish-color-light-grey-3: #f0f0f5;
  --bluish-color-light-grey-4: #f7f7fa;
  --bluish-color-light-grey-5: #f9f9fb;

  /***********************
   * SHADES OF GREY
   ***********************/
  --grey-color-hover: #5050500a;
  --grey-color-dark-1: #4b4b4b;
  --grey-color-dark-2: #7a7a7a;
  --grey-color-dark-3: #939393;
  --grey-color-dark-4: rgba(0 0 0 / 8%);
  --grey-color-dark-5: #44546F;
  --grey-color-light-1: #bdbdbd;
  --grey-color-light-2: #dadada;
  --grey-color-light-3: #e7e7e7;
  --grey-color-light-4: #f2f2f2;
  --grey-color-light-5: #fafafa;
  --grey-color-light-6: #98a2b3;
  --grey-color-light-7: #faf9ff;

  /***********************
   * GREEN
   ***********************/
  --light-green: #cbffc2;
  --light-green-2: #d7eae2;
  --light-green-3: #f4f9f7;
  --medium-green: #30a44a;
  --medium-green-2: #12c782;

  /***********************
   * PURPLE
   ***********************/
  --purple-2: #8f99f0;
  --purple-3: #9747ff;
  --purple-200: #dcdffa;

  /***********************
   * ORANGE
   ***********************/
  --light-orange: #FFE2DA;
  --vivid-orange: #FF8B1F;

  /***********************
  * INPUT
  ***********************/
  --input-text-color: rgba(0 0 0 / 54%);
  --input-error-border-color: #f33;
  --input-error-bg-color: #ffebeb;
  --input-secondary-text-color: rgba(255 255 255 / 60%);

  /***********************
   * DISABLED STATE (GENERAL)
   ***********************/
  --disabled-opacity: 38%;
  --disabled-text: rgb(0 0 0 / var(--disabled-opacity));

  /***********************
   * OTHERS
   ***********************/
  --background-color: white;
  --background-color-light-grey: #efeff5;
  --card-disc-color: var(--primary-color);
  --light-red: #ffc2c2;
  --medium-red: #eb4e4e;
  --medium-red-2: #ce5749;
  --divider-color: #e8e8e8;
  --dark-grey-color: #7b7c90;
  --dark-grey-color-hover: #3d3e5a;
  --black-color: #000;
  --shaded-text-color: #9292ac;
}
