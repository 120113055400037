@import 'customcolors';

.mat-mdc-form-field-infix .mat-mdc-chip-set {
  --mdc-chip-container-height: auto;

  display: block;
  overflow: hidden;
  width: 100%;

  .mdc-evolution-chip-set__chips {
    align-items: center;
    display: flex;

    .mat-mdc-chip.mat-mdc-standard-chip {
      min-height: 26px;
    }

    .mdc-text-field__input {
      flex: 1 0;
      padding: 8px 0;
    }
  }

  &.mat-mdc-chip-list-invalid {
    .mdc-evolution-chip-set__chips {
      border-color: var(--input-error-border-color);
      background-color: var(--input-error-bg-color);
    }
  }
}

.chips-outside.mat-mdc-chip-set .mdc-evolution-chip-set__chips {
  align-items: start;
  margin: 0;
}
