@import 'customcolors';

:host {
  --event-creation-header-height: 38px;
  --page-content-padding: 12px;
}

@mixin offset-margin($margin, $value){
  margin-#{$margin}: $value;
}

@mixin offset-padding($margin, $value){
  padding-#{$margin}: $value;
}

@mixin full-width-content() {
  margin-left: -1.5rem;
  margin-right: -1.5rem;
}

@mixin full-content() {
  display: flex;
  flex-direction: column;
  flex: 1;
}

@mixin creator-header-content() {
  position: sticky;
  top: 0;
  height: calc(var(--event-creation-header-height) + 2 * var(--page-content-padding));
  z-index: 4;
  padding: var(--page-content-padding);
  margin-bottom: 8px;
  background-color: var(--bluish-color-light-grey-4);
  box-shadow: 0 2px 16px rgba(0 0 0 / 8%);
}

@for $i from 1 through 6 {
  .ml-#{$i}{
    @include offset-margin(left, 0.25rem * $i);
  }

  .mr-#{$i}{
    @include offset-margin(right, 0.25rem * $i);
  }

  .mx-#{$i}{
    @include offset-margin(left, 0.25rem * $i);
    @include offset-margin(right, 0.25rem * $i);
  }

  .mt-#{$i}{
    @include offset-margin(top, 0.25rem * $i);
  }

  .mb-#{$i}{
    @include offset-margin(bottom, 0.25rem * $i);
  }

  .my-#{$i}{
    @include offset-margin(top, 0.25rem * $i);
    @include offset-margin(bottom, 0.25rem * $i);
  }

  .pl-#{$i}{
    @include offset-padding(left, 0.25rem * $i);
  }

  .pr-#{$i}{
    @include offset-padding(right, 0.25rem * $i);
  }

  .px-#{$i}{
    @include offset-padding(left, 0.25rem * $i);
    @include offset-padding(right, 0.25rem * $i);
  }

  .pt-#{$i}{
    @include offset-padding(top, 0.25rem * $i);
  }

  .pb-#{$i}{
    @include offset-padding(bottom, 0.25rem * $i);
  }

  .py-#{$i}{
    @include offset-padding(top, 0.25rem * $i);
    @include offset-padding(bottom, 0.25rem * $i);
  }

}

.d-block {
  display: block;
}

.d-flex {
  display: flex;
}

.v-center {
  display: flex;
  align-items: center;
}

.ml-auto {
  margin-left: auto;
}

.mt-auto {
  margin-top: auto;
}

.w-100 {
  width: 100%;
}
