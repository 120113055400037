:root {
  --input-height: 44px;
  --channel-settings-dropdown-width: min(305px, 100%);
  --page-content-margin: 20px;
  --page-content-margin-mobile: 11px;
  --progress-bar-height: 5px;
  --header-height: 62px;
  --scrollbar-width: 5px;
}

$sidebar-width: 260px;
$sidebar-width-minimalized: 50px;
$title-bar-height: 60px;
$profile-height: 70px;
$submenu-width: 220px;
$submenu-item-height: 50px;
$base-z-index: 1;
$main-option-filter-max-width: 600px;
