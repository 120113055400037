@keyframes slide-in-right {
  from {
    transform: translate3d(100%, 0, 0) scale(1);
    visibility: visible;
  }

  to {
    transform: translate3d(0, 0, 0) scale(1);
  }
}

@keyframes slide-out-right {
  from {
    transform: translate3d(0, 0, 0) scale(1);
  }

  to {
    visibility: hidden;
    transform: translate3d(100%, 0, 0) scale(1);
  }
}
