@import 'customcolors';

.material-icons {
  user-select: none;
}

.action-warning {
  color: $status-orange;
}

.link-icon {
  display: inline-block;
  transition: opacity 0.25s ease-in-out;

  &.disabled {
    cursor: not-allowed;
    opacity: 0.4;
    pointer-events: none;
  }

  .material-icons {
    transition: background-color 0.15s ease-in-out;
  }

  &.link-action-icon {
    .material-icons {
      &.action-primary {
        color: $action-primary-color;
      }
    }

    &:hover {
      background-color: $action-primary-hover-color;
    }
  }

  &.link-edit-icon {
    .material-icons {
      &.edit-primary {
        color: $edit-primary-color;
      }
    }

    &:hover {
      background-color: $edit-primary-hover-color !important;
    }
  }

  &.link-delete-icon {
    .material-icons {
      &.delete-primary {
        color: $delete-primary-color;
      }
    }

    &:hover {
      background-color: $delete-primary-hover-color;
    }
  }

  &.link-done-icon {
    .material-icons {
      &.done-primary {
        color: $green;
      }
    }

    &:hover {
      background-color: $face-primary-color;
    }
  }

  &.link-sublist-icon {
    .material-icons {
      &.sublist-primary {
        color: $edit-primary-color;
      }
    }

    &:hover {
      background-color: $edit-primary-hover-color !important;
    }
  }

  &.link-face-icon {
    .material-icons {
      &.face-primary {
        color: $face-primary-color;
      }
    }

    &:hover {
      background-color: $face-primary-hover-color;
    }
  }

  &.link-kick-icon {
    .material-icons {
      &.kick-primary {
        color: $kick-primary-color;
      }
    }

    &:hover {
      background-color: $kick-primary-hover-color;
    }
  }

  &.link-email-icon {
    .material-icons {
      &.email-primary {
        color: $email-primary-color;
      }
    }

    &:hover {
      background-color: $email-primary-hover-color;
    }
  }
}

$icon-size-list: 6, 12, 18, 24, 32, 48, 64;

.it-icon-img {
  width: 24px;
  height: 24px;
  padding: 0;
  background-size: cover;

  @each $size in $icon-size-list {
    &.size-#{$size} {
      width: #{$size}px;
      height: #{$size}px;
    }
  }
}

.it-loader,
.it-loader-center {
  width: 32px;
  height: 32px;
  border-width: 4px;
  border-style: solid;
  border-color: $primary-color rgba(0 0 0 / 12%) rgba(0 0 0 / 12%);
  margin: 2px;
  animation: rotation 1s infinite linear;
  border-radius: 100%;
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(359deg);
  }
}

button.it-loader-button,
.button.it-loader-button {
  padding-right: 3em;
  cursor: default;

  .loader-icon {
    position: absolute;
    top: 6px;
    right: 0.5em;
    width: 23px;
    height: 23px;
    border-width: 4px;
    border-style: solid;
    border-color: rgba(0 0 0 / 0%) $white $white;
    margin: 2px;
    animation: rotation 1s infinite linear;
    border-radius: 100%;
  }

  &.hollow {
    .loader-icon {
      border-color: inherit;
      border-top-color: rgba(0 0 0 / 0%);
    }
  }
}

.upload-image {
  display: flex;
  width: 222px;
  height: 130px;
  align-items: center;
  justify-content: center;
  border: 1px solid #e6e6e6;
  margin: 0.5rem 1.5rem 1rem 0;
  background-color: #f0f0f0;
  cursor: pointer;
  float: left;
  text-align: center;
}
