@mixin font-family-roboto() {
  font-family: Roboto, Inter, 'Helvetica Neue', sans-serif;
}

@mixin font-family-inter() {
  font-family: Inter, Roboto, 'Helvetica Neue', sans-serif;
}

@mixin font-family-neuron() {
  font-family: Neuron-Bold, Arial, sans-serif;
}

@mixin font-family-open-sans() {
  font-family: "Open Sans", Montserrat, "Helvetica Neue", Helvetica, Roboto, Arial, sans-serif;
}

@mixin font-empty-desc() {
  font-family: Roboto, Inter, 'Helvetica Neue', sans-serif;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: var(--secondary-text-color);
}

:root {
  --font-family-roboto: roboto, inter, 'Helvetica Neue', sans-serif;
  --font-family-inter: inter, roboto, 'Helvetica Neue', sans-serif;
}
