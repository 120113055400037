/* stylelint-disable no-invalid-position-at-import-rule */
@use '@angular/material' as mat;

@charset "utf-8";

@import 'normalize.css';

// Angular Material 2 themes
@import '@angular/cdk/overlay-prebuilt.css';
@import 'material-app-theme';

// App scss settings
@import 'settings';

// Foundation general includes and imports
@import 'foundation-sites/scss/foundation';

@include foundation-global-styles;
@include foundation-flex-grid;
@include foundation-flex-classes;
@include foundation-typography;
@include foundation-button;
@include foundation-forms;
@include foundation-breadcrumbs;
@include foundation-button-group;
@include foundation-callout;
@include foundation-responsive-embed;
@include foundation-label;
@include foundation-visibility-classes;
@include foundation-prototype-classes;

// DateTimePicker
@import '@danielmoncada/angular-datetime-picker/assets/style/picker.min.css';

// Custom app stylesheets
@import 'breakpoints';
@import 'fonts';
@import 'font-families';
@import 'customcolors';
@import 'icons';
@import 'table';
@import 'callouts';
@import 'pres';
@import 'buttons';
@import 'inputs';
@import 'textareas';
@import 'loader';
@import 'paragraphs';
@import 'flex-utils';
@import 'dragula';
@import 'variables';
@import 'layout';
@import 'font-styles';
@import 'print';

// UI-Kit
@import 'variables/colors';
@import 'variables/layout';
@import 'components/typography';
@import 'components/toastr';
@import 'components/filter-dropdown';
@import 'components/buttons';
@import 'components/date-time-picker';
@import 'components/modal';
@import 'components/select';
@import 'components/forms';
@import 'components/drag-drop';
@import 'components/input';

// ITDS
@import 'itds/variables/colors';
@import 'itds/variables/spacings';
@import 'itds/variables/radius';
@import 'itds/variables/palettes';

// Swiper
@import 'swiper/scss';
@import 'swiper/scss/pagination';
@import 'swiper/scss/navigation';

// Angular-calendar
@import 'angular-calendar/scss/angular-calendar';

// Material overrides
@import 'mat-card-overrides';
@import 'mat-icon-overrides';
@import 'mat-table-overrides';
@import 'mat-tooltip-overrides';
@import 'mat-chip-overrides';
@import 'mat-select-overrides';
@import 'mat-form-field-overrides';
@import 'mat-slide-toggle-overrides';
@import 'mat-radio-overrides';
@import 'mat-checkbox-overrides';
@import 'mat-tab-overrides';
@import 'mat-menu-overrides';

// Customer-Facing UI-Kit for Public Survey Viewer
@import '@interticket/customer-facing-ui-kit/styles/colors';
@import '@interticket/customer-facing-ui-kit/styles/forms';
@import '@interticket/customer-facing-ui-kit/styles/palettes';
@import '@interticket/customer-facing-ui-kit/styles/spacings';
@import '@interticket/customer-facing-ui-kit/styles/variables';

.cal-month-view .bg-white,
.cal-week-view .cal-day-columns .bg-white,
.cal-day-view .bg-white {
  background-color: #fff;
}

.event-dark-title .cal-event-title {
  color: #3d3e5a;
}

.cal-month-view .bg-closing-hour,
.cal-week-view .cal-day-columns .bg-closing-hour,
.cal-day-view .bg-closing-hour {
  background-color: #4a4a4a;
}

*,
*::after,
*::before {
  box-sizing: border-box;
}

body,
html {
  height: 100%;
  min-height: 100%;
}

body {
  font-size: 13px;

  &.locked {
    overflow: hidden;
  }
}

p {
  &.lead {
    margin-left: 10px;
    word-wrap: break-word;
  }
}

.highlight {
  color: $primary-color !important;
}

.italic {
  font-style: italic;
}

.bold {
  font-weight: bold;
}

legend {
  color: $black;
}

// this is important, overrides the default behaviour of the row from foundation
.row {
  margin-right: 0;
  margin-left: 0;
}

.mat-input-underline {
  display: none;
}

.mat-radio-label {
  margin-right: 20px;
}

.material-icons {
  margin-right: 3px;
  margin-left: 3px;
}

.mat-mdc-cell .material-icons {
  margin-top: 8px;
}

.toast-container .toast {
  background-position: 15px 15px !important;
}

.cdk-overlay-container {
  ::-webkit-scrollbar {
    width: var(--scrollbar-width);
  }

  ::-webkit-scrollbar-track {
    background: transparent;
  }

  ::-webkit-scrollbar-thumb {
    background: #838489;
    border-radius: var(--scrollbar-width);
  }

  .full-screen-dialog {
    .mat-mdc-dialog-container {
      padding: 0;
      margin: 0;
      border-radius: 0;
      min-width: 100%;
    }
  }

  .menu-dropdown-panel {
    max-width: 100%;
    max-height: 500px;
    margin-top: 5px;

    .mat-mdc-menu-content {
      overflow: hidden;
      padding: 15px;
    }
  }

  .mat-menu-auto-height {
    min-height: auto;
  }

  .cdk-overlay-pane:not(.editor-ui-kit-autocomplete-overlay) .mdc-menu-surface.mat-mdc-autocomplete-panel:not(.autocomplete-panel) {
    padding: 0;
    border: 1px solid $light-gray;
    background-color: $white;
    box-shadow: none !important;

    .mat-mdc-option:not(.editor-mat-option) {
      min-height: 0;
      height: 39px;
      color: $secondary-color;
      font-size: 13px;
      padding: 0;

      .mdc-list-item__primary-text {
        padding: 0 16px;

        &:has(> div) {
          width: 100%;
          height: 100%;
          padding: 0;

          > div {
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            padding: 0 16px;
          }
        }
      }

      .mat-mdc-option-pseudo-checkbox {
        display: none;
      }

      &.mdc-list-item--selected .mdc-list-item__primary-text {
        color: $secondary-color;
      }

      &:hover {
        background-color: $select-hover !important;

        .mdc-list-item__primary-text {
          color: $cyan;
        }
      }
    }

    &.program-recommendation .mat-mdc-option {
      height: 75px;

      .program-datas-col {
        padding: 0;
      }
    }
  }
}

textarea {
  resize: vertical;
}

[appForm] label {
  margin-bottom: 4px;

  &.no-bottom-margin {
    margin-bottom: 0;
  }
}

app-show-errors.independent-error {
  margin: 0 0 4px;
}

.reset-padding {
  padding: 0 !important;
}

/* stylelint-disable-next-line scss/dollar-variable-colon-newline-after */
$base-typography: mat.define-typography-config(
  $font-family: 'Open Sans, Montserrat, Helvetica Neue, Helvetica, Roboto, Arial, sans-serif',
  $headline-5: mat.define-typography-level(32px, 48px, 700),
  $body-2: mat.define-typography-level(13px, 20px, 500),
);

@include mat.all-component-typographies($base-typography);
