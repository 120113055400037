.mat-mdc-checkbox.mat-accent:not(.editor-ui-kit-checkbox) {
  --mdc-checkbox-selected-icon-color: var(--mdc-checkbox-bg-color, var(--blue-color));
  --mdc-checkbox-selected-checkmark-color: var(--mdc-checkbox-mark-color, white);
  --mdc-checkbox-unselected-icon-color: var(--mdc-checkbox-border-color, #333);

  // disabled
  --mdc-checkbox-disabled-selected-icon-color: var(--mdc-checkbox-bg-color, var(--blue-color));
  --mdc-checkbox-disabled-unselected-icon-color: var(--mdc-checkbox-mark-color, white);

  // hover
  --mdc-checkbox-selected-hover-icon-color: var(--mdc-checkbox-bg-color, var(--blue-color));
  --mdc-checkbox-unselected-hover-icon-color: var(--mdc-checkbox-border-color, #333);

  // focus
  --mdc-checkbox-selected-focus-icon-color: var(--mdc-checkbox-bg-color, var(--blue-color));
  --mdc-checkbox-unselected-focus-icon-color: var(--mdc-checkbox-border-color, #333);

  // pressed
  --mdc-checkbox-selected-pressed-icon-color: var(--mdc-checkbox-bg-color, var(--blue-color));
  --mdc-checkbox-unselected-pressed-icon-color: var(--mdc-checkbox-bg-color, var(--blue-color));

  // base styles
  --mdc-checkbox-size: var(--mdc-checkbox-size-override, 16px);
  --mdc-checkbox-state-layer-size: var(--mdc-checkbox-state-layer-size-override, 26px);
  --mdc-checkbox-font-family: var(--mdc-checkbox-font-family-override, revert);

  .mdc-form-field {
    input:disabled {
      opacity: 0
    }

    > label {
      margin-bottom: 0;
      margin-left: -2px;
      padding-left: 4px;
      font-family: var(--mdc-checkbox-font-family);
      font-size: var(--mdc-checkbox-label-font-size, 16px);
      font-weight: var(--mdc-checkbox-label-font-weight, 400);
      color: var(--mdc-checkbox-label-color, #333);
      line-height: var(--mdc-checkbox-label-line-height, revert);
      display: inline-flex;
      align-items: center;
    }

    .mat-mdc-checkbox-touch-target {
      width: var(--mdc-checkbox-size, 18px);
      height: var(--mdc-checkbox-size, 18px);
    }
  }

  .mdc-checkbox__ripple {
    background: var(--mdc-checkbox-selected-icon-color);
  }

  .mdc-checkbox__background {
    border-width: 2px;
    border-radius: 3px;
    width: var(--mdc-checkbox-size, 18px);
    height: var(--mdc-checkbox-size, 18px);
    top: calc((var(--mdc-checkbox-state-layer-size, 40px) - var(--mdc-checkbox-size, 18px)) / 2) !important;
    left: calc((var(--mdc-checkbox-state-layer-size, 40px) - var(--mdc-checkbox-size, 18px)) / 2) !important;
  }

  &.mat-mdc-checkbox-disabled {
    opacity: var(--disabled-opacity-override, var(--disabled-opacity));
    cursor: not-allowed;
  }

  &.has-white-bg {
    .mdc-checkbox__native-control:not(:checked)~.mdc-checkbox__background {
      background-color: white !important;
    }
  }

  &:not(.has-white-bg) {
    .mdc-checkbox__native-control:enabled:not(:checked, :indeterminate, [data-indeterminate="true"])~.mdc-checkbox__background {
      background-color: var(--mdc-checkbox-bg-color, transparent) !important;
    }
  }

  .mat-checkbox-label {
    font-size: var(--mdc-checkbox-label-font-size, 16px);
    font-weight: var(--mdc-checkbox-label-font-weight, 400);
    color: var(--mdc-checkbox-label-color, #333);
    line-height: var(--mdc-checkbox-label-line-height, revert);

    .mat-mdc-checkbox {
      margin-right: -8px;

      .mdc-checkbox__background {
        border-width: 2px;
      }
    }
  }
}
