/**
 * ITDS Colors
 * @source https://www.figma.com/design/7CHWhlRnk29dfZHcME3G0O/%F0%9F%8E%A8-Admin-Components---Interticket-Design-System?node-id=1339-91728&vars=1&m=dev
 */
:root {
  // BRAND BLUE
  --itds-brand-blue-50: #e2f3ff;
  --itds-brand-blue-100: #c2e8ff;
  --itds-brand-blue-200: #78d1ff;
  --itds-brand-blue-300: #4ab6e8;
  --itds-brand-blue-400: #209bcc;
  --itds-brand-blue-500: #0080ab;
  --itds-brand-blue-600: #006689;
  --itds-brand-blue-700: #004d68;
  --itds-brand-blue-800: #003549;
  --itds-brand-blue-900: #001e2c;

  // BRIGHT BLUE
  --itds-bright-blue-50: #e9f2ff;
  --itds-bright-blue-100: #cce0ff;
  --itds-bright-blue-200: #84b8ff;
  --itds-bright-blue-300: #579dff;
  --itds-bright-blue-400: #388bff;
  --itds-bright-blue-500: #1d7afc;
  --itds-bright-blue-600: #0c66e4;
  --itds-bright-blue-700: #05c;
  --itds-bright-blue-800: #09326c;
  --itds-bright-blue-900: #1c2b41;

  // GREEN
  --itds-green-50: #ecf8f2;
  --itds-green-100: #baf3db;
  --itds-green-200: #7ee2b8;
  --itds-green-300: #4bce97;
  --itds-green-400: #2abb7f;
  --itds-green-500: #22a06b;
  --itds-green-600: #1f845a;
  --itds-green-700: #216e4e;
  --itds-green-800: #164b35;
  --itds-green-900: #1c3329;

  // NEUTRAL
  --itds-neutral-50: #fff;
  --itds-neutral-100: #f7f8f9;
  --itds-neutral-150: #f1f2f4;
  --itds-neutral-200: #dcdfe4;
  --itds-neutral-250: #cfd2d7;
  --itds-neutral-300: #b3b9c4;
  --itds-neutral-350: #8590a2;
  --itds-neutral-400: #758195;
  --itds-neutral-500: #626f86;
  --itds-neutral-600: #44546f;
  --itds-neutral-700: #2c3e5d;
  --itds-neutral-800: #172b4d;
  --itds-neutral-800-02: #172b4d02;
  --itds-neutral-800-04: #172b4d04;
  --itds-neutral-800-08: #172b4d08;
  --itds-neutral-800-12: #172b4d12;
  --itds-neutral-800-16: #172b4d16;
  --itds-neutral-800-32: #172b4d32;
  --itds-neutral-800-56: #172b4d56;
  --itds-neutral-900: #051228;

  // ORANGE
  --itds-orange-50: #feefe6;
  --itds-orange-100: #fcd0b3;
  --itds-orange-200: #fbc199;
  --itds-orange-300: #f9a166;
  --itds-orange-400: #f78233;
  --itds-orange-500: #f57721;
  --itds-orange-600: #f56300;
  --itds-orange-700: #c44f00;
  --itds-orange-800: #933b00;
  --itds-orange-900: #622800;

  // PINK
  --itds-pink-50: #fef1fa;
  --itds-pink-100: #ffe1f5;
  --itds-pink-200: #ffc3eb;
  --itds-pink-300: #ffa5e0;
  --itds-pink-400: #f58cc8;
  --itds-pink-500: #e76eb8;
  --itds-pink-600: #cf5a9f;
  --itds-pink-700: #b84b89;
  --itds-pink-800: #a13c75;
  --itds-pink-900: #8b2f62;

  // PURPLE
  --itds-purple-50: #f0e2ff;
  --itds-purple-100: #e2c7ff;
  --itds-purple-200: #d4acfe;
  --itds-purple-300: #c894ff;
  --itds-purple-400: #b46eff;
  --itds-purple-500: #9b4eec;
  --itds-purple-600: #812fd1;
  --itds-purple-700: #6900ba;
  --itds-purple-800: #480082;
  --itds-purple-900: #2b0052;

  // RED
  --itds-red-50: #faeeed;
  --itds-red-100: #ffd5d2;
  --itds-red-200: #fd9891;
  --itds-red-300: #f87168;
  --itds-red-400: #f15b50;
  --itds-red-500: #e2483d;
  --itds-red-600: #c9372c;
  --itds-red-700: #ae2e24;
  --itds-red-800: #8b1d1d;
  --itds-red-900: #651515;

  // YELLOW
  --itds-yellow-50: #fff9e6;
  --itds-yellow-100: #f8e6a0;
  --itds-yellow-200: #f5cd47;
  --itds-yellow-300: #e2b203;
  --itds-yellow-400: #cf9f02;
  --itds-yellow-500: #b38600;
  --itds-yellow-600: #946f00;
  --itds-yellow-700: #7f5f01;
  --itds-yellow-800: #533f04;
  --itds-yellow-900: #332e1b;
}
