// font-sizes
$font-size-xxs: 0.72rem;
$font-size-xs: 0.84rem;
$font-size-s: 1rem;
$font-size-m: 1.07rem;
$font-size-l: 1.15rem;
$font-size-xl: 1.23rem;
$font-size-xxl: 1.3rem;
$font-size-span: 3.84rem;

// font-weight
$font-weight-xs: 400;
$font-weight-s: 500;
$font-weight-m: 600;
$font-weight-l: 700;
$font-weight-xl: 800;

// font-style
$font-style-italic: italic;
