@import 'customcolors';

.mat-mdc-row {
  transition: background-color 0.15s ease-in-out;
}

.mat-mdc-row:hover {
  background-color: $table-row-hover !important;
}

.mat-mdc-row:nth-child(even) {
  background-color: $table-row-even-bg;
}

.mat-mdc-row:nth-child(odd) {
  background-color: $table-row-odd-bg;
}

.mat-mdc-cell.mdc-data-table__cell,
.mat-mdc-header-cell.mdc-data-table__header-cell,
.mat-mdc-footer-cell.mdc-data-table__cell {
  --mat-table-row-item-label-text-font: var(--font-family-roboto);
  --mat-table-row-item-label-text-weight: 400;
  --mat-table-row-item-label-text-line-height: 20px;

  &:last-child {
    flex: 0 0 120px;
    margin-left: auto;
  }
}

mat-header-cell {
  word-break: break-word;
}

mat-cell {
  color: $primary-font-color !important;
  word-break: break-word;
}

mat-table.mdc-data-table__table,
table[mat-table],
table {
  width: 100%;
  border-top: 2px solid $table-border;
  box-shadow: none !important;
}

.mat-mdc-header-cell.header-cell {
  color: $primary-font-color;
  font-size: 12px;
  font-weight: $font-weight-m !important;
}

mat-paginator {
  border-bottom: 2px solid $table-border;

  .mdc-text-field--filled:not(.mdc-text-field--disabled) {
    background-color: transparent;
    padding-inline: 2px;
  }

  .mat-mdc-text-field-wrapper {
    height: 35px;
    margin-bottom: 10px;
  }
}

.datatable-container {
  position: relative;
  display: flex;
  min-width: 300px;
  flex-direction: column;

  .datatable-content-scrollable {
    display: block;
    width: 100%;
    overflow-x: scroll;

    &::-webkit-scrollbar {
      height: 7px;
    }

    &::-webkit-scrollbar-track {
      background: transparent;
    }

    &::-webkit-scrollbar-thumb {
      background: #c1c1c1;
      border-radius: 2px;

      &:hover {
        background: #9c9c9c;
      }
    }

    &.max-table-width {
      mat-table {
        min-width: 100%;
      }
    }

    mat-table {
      width: max-content;
    }
  }

  .datatable-loading-shade {
    position: absolute;
    z-index: 1;
    inset: 0 0 56px;
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: center;
    background: rgba(0 0 0 / 15%);

    .datatable-message {
      max-width: 360px;
      margin-top: 230px;
      color: $red-dark;
      text-align: center;
    }
  }

  .image-cell {
    width: 170px;
    min-width: 170px;
    max-width: 170px;
  }
}
