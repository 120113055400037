@import 'customcolors';

.text-length-counter {
  @include font-family-roboto;

  position: absolute;
  bottom: 15px;
  right: 16px;
  color: var(--bluish-color-dark-grey-2);
  font-size: 12px;
  font-style: normal;
  font-weight: 500;

}

ui-kit-textarea  > .text-length-counter {
  bottom: 21px;
}

ui-kit-text-editor > .text-length-counter {
  bottom: 23px;
}

.text-too-long {
  color: var(--error-color);
}
