// Styles for ui-kit-popup-menu
.ui-kit-popup-menu {
  --ui-kit-filter-dropdown-padding: 0;

  &.no-min-width {
    --ui-kit-popup-menu-min-width: 0;
  }

  min-height: auto;

  &.mat-mdc-menu-panel.filter-dropdown-panel {
    .mat-mdc-menu-content {
      max-width: var(--ui-kit-popup-menu-max-width, 500px);
      min-width: var(--ui-kit-popup-menu-min-width, 284px);
    }
  }
}
