@import 'mixins/typography';
@import 'variables/animations';

:root {
  --modal-padding: 25px;
}

@mixin apply-base-styles {
  --mdc-dialog-container-color: transparent;
  --mdc-dialog-container-elevation: none;
  --mdc-dialog-subhead-font: roboto;
  --mdc-dialog-supporting-text-font: roboto;
  --mdc-dialog-supporting-text-line-height: 20.8px;
  --mdc-dialog-supporting-text-tracking: normal;
  --mdc-dialog-container-shape: var(--modal-border-radius, 12px);
  --mdc-dialog-supporting-text-size: 13px;
}

.cdk-overlay-container {
  mat-dialog-container {
    --mdc-dialog-subhead-color: var(--blue-color, white);
  }

  .reset-border-radius {
    mat-dialog-container {
      .mat-mdc-dialog-surface {
        --mdc-dialog-container-shape: 0;
      }
    }
  }

  .ui-kit-modal-panel {
    mat-dialog-container {
      @include apply-base-styles;

      .mat-mdc-dialog-surface {
        padding: var(--modal-padding);
      }
    }
  }

  .ui-kit-mobile-filter-modal-panel {
    height: 100%;
    width: 100%;
    max-width: none !important;

    mat-dialog-container {
      @include apply-base-styles;

      .mat-mdc-dialog-surface {
        --mdc-dialog-container-color: white;
        --mdc-dialog-container-shape: 0;
      }
    }
  }

  .ui-kit-modal-panel-side {
    --modal-border-radius: 0;

    $modal-header-height: 76px;
    $modal-actions-height: 86px;

    mat-dialog-container {
      @include apply-base-styles;

      padding: 0;
      border-radius: 0;

      ui-kit-modal {
        border-radius: 0;
      }

      mat-dialog-content {
        max-height: initial;
        height: calc(100vh - #{$modal-header-height} - #{$modal-actions-height});
      }

      &.mdc-dialog--opening {
        .mdc-dialog__container {
          animation-name: slide-in-right;
          animation-duration: var(--mat-dialog-transition-duration);
          animation-fill-mode: both;
        }
      }

      &.mdc-dialog--closing {
        .mdc-dialog__container {
          animation-name: slide-out-right;
          animation-duration: var(--mat-dialog-transition-duration);
          animation-fill-mode: both;
        }
      }
    }

    &.ui-kit-modal-panel-size-tiny {
      width: 300px;
    }

    &.ui-kit-modal-panel-size-small {
      width: 480px;
    }

    &.ui-kit-modal-panel-size-medium {
      width: 600px;
    }

    &.ui-kit-modal-panel-size-large {
      width: 900px;
    }

    &.ui-kit-modal-panel-without-actions {
      mat-dialog-container {
        mat-dialog-content {
          height: calc(100vh - #{$modal-header-height});
        }
      }
    }
  }

  .ui-seating-chart-modal-panel {
    padding: 15px;
  }
}

.dialog-without-padding {
  .mat-mdc-dialog-container {
    padding: 0 !important;
  }
}

@media screen and (max-width: $large) {
  .multi-modal {
    max-height: 80vh;
  }
}
