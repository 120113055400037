/**
 * ITDS Radius
 * @source https://www.figma.com/design/7CHWhlRnk29dfZHcME3G0O/%F0%9F%8E%A8-Admin-Components---Interticket-Design-System?node-id=1339-91728&vars=1&m=dev
 */
:root {
  --itds-radius-0: 0px;
  --itds-radius-2: 2px;
  --itds-radius-4: 4px;
  --itds-radius-6: 6px;
  --itds-radius-8: 8px;
  --itds-radius-10: 10px;
  --itds-radius-12: 12px;
  --itds-radius-14: 14px;
  --itds-radius-16: 16px;
}
