@import 'variables/palettes';
@import 'mixins/typography';

.mat-mdc-menu-panel.mat-mdc-menu-panel {
  &.filter-dropdown-panel {
    width: 100%;
    min-width: 150px;
    max-width: 500px;
    max-height: 100%;
    margin: 5px 0;
    border: 1px solid var(--grey-color-light-4);
    box-shadow: 0 1px 10px rgba(0 0 0 / 8%);
    border-radius: 8px;
    color: var(--secondary-text-color);

    @each $palette in $palettes {
      &.ui-kit-palette-#{$palette} {
        .filter-dropdown-option.checked {
          background-color: var(--#{$palette}-color) !important;
        }
      }
    }

    &.is-select {
      max-height: 250px;
    }

    &.dropdown-label {
      ui-kit-dropdown-item ui-kit-button button {
        cursor: default !important;
      }
    }

    .mat-mdc-menu-content {
      overflow: hidden;
      padding: var(--ui-kit-filter-dropdown-padding, 8px) !important;

      .filter-dropdown-content {
        @include font-family-roboto;

        display: flex;
        flex-direction: column;
        margin: 0 -8px;

        .filter-dropdown-select {
          display: flex;
          flex-direction: column;
          flex: 1;
          width: 250px;
          max-width: 100%;
          max-height: 215px;
          margin: 0;
          padding: 0 8px;
          overflow: auto;

          .filter-dropdown-option {
            display: flex;
            align-items: center;
            margin: 2px 0;
            padding: 8px 10px;
            border-radius: 6px;
            font-size: 14px;
            font-weight: 500;
            color: var(--secondary-text-color);
            transition: opacity 0.25s ease-in-out, background-color 0.25s ease-in-out, color 0.25s ease-in-out;
            cursor: pointer;

            &:hover:not(.disabled) {
              background-color: var(--grey-color-light-4);
            }

            &.checked {
              color: white !important;
            }

            &.disabled {
              opacity: 0.5;
              cursor: not-allowed;
            }

            ui-kit-icon {
              margin-right: 5px;
            }
          }
        }

        .filter-dropdown-date-picker {
          position: relative;
          margin: -8px 0;

          owl-date-time-inline {
            margin-bottom: 10px;
          }

          .validation-message {
            margin: -20px 0 0;
            padding: 0 8px;
            display: flex;
            justify-content: flex-end;
            width: 100%;
            color: var(--error-color);
            font-size: 12px;
            font-style: italic;
            line-height: 20px;
          }

          .button-container {
            display: flex;
            justify-content: space-between;
            padding: 8px;
          }

          owl-date-time-container {
            box-shadow: none;
            background-color: transparent;
          }

          ui-kit-spinner {
            position: absolute;
            inset: 0;
            z-index: 1001;
            background-color: rgb(255 255 255 / 60%);
          }
        }
      }
    }

    &.auto-height {
      .mat-mdc-menu-content {
        .filter-dropdown-select {
          max-height: unset;
        }
      }
    }

    &.content-scroll {
      .mat-mdc-menu-content {
        --ui-kit-filter-dropdown-padding: 0;

        display: flex;
        flex-direction: column;
        overflow-y: auto;
      }
    }

    &.content-scroll,
    .content-scroll {
      display: flex;
      flex-direction: column;
      overflow-y: auto;
    }
  }
}
