/**
 * Add breakpoint for debugging: https://stackoverflow.com/a/64251559/13183117
 */
@import 'mixins/typography';

.ui-kit-drag {
  @include font-family-roboto;

  &.cdk-drag-animating {
    transition: 250ms cubic-bezier(0, 0, 0.2, 1);
  }

  &.cdk-drag-preview {
    box-sizing: border-box;
    border-radius: 8px;
    box-shadow: 0 5px 5px -3px rgba(0 0 0 / 20%), 0 8px 10px 1px rgba(0 0 0 / 14%), 0 3px 14px 2px rgba(0 0 0 / 12%);

    .no-shrink {
      flex: 1 0 auto;
    }
  }
}

.ui-kit-drop-list {
  &.cdk-drop-list-dragging {
    .ui-kit-drag {
      &:not(.cdk-drag-placeholder) {
        transition: 250ms cubic-bezier(0, 0, 0.2, 1);
      }
    }
  }
}
