@import 'mixins/typography';

.mat-mdc-slide-toggle:not(.editor-slide-toggle) {
  .mdc-switch {
    --mdc-switch-track-height: 18px;
    --mdc-switch-track-shape: 9px;
    --mdc-switch-track-width: 34px;
    --mdc-switch-handle-width: 16px;
    --mdc-switch-handle-height: 16px;
    --mdc-switch-state-layer-size: 35px;
    --mdc-switch-handle-box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);

    .mdc-switch__track {
      --mdc-switch-unselected-track-color: var(--grey-color-light-2);
      --mdc-switch-unselected-focus-track-color: var(--grey-color-light-2);
      --mdc-switch-unselected-hover-track-color: var(--grey-color-light-2);
      --mdc-switch-unselected-pressed-track-color: var(--grey-color-light-2);
      --mdc-switch-selected-track-color: var(--switch-color, var(--blue-color));
      --mdc-switch-selected-focus-track-color: var(--switch-color, var(--blue-color));
      --mdc-switch-selected-hover-track-color: var(--switch-color, var(--blue-color));
      --mdc-switch-selected-pressed-track-color: var(--switch-color, var(--blue-color));
    }

    .mdc-switch__handle {
      --mdc-switch-disabled-handle-opacity: 1;
      --mdc-switch-disabled-selected-handle-color: #fafafa;
      --mdc-switch-unselected-handle-color: #fafafa;
      --mdc-switch-unselected-focus-handle-color: #fafafa;
      --mdc-switch-unselected-hover-handle-color: #fafafa;
      --mdc-switch-unselected-pressed-handle-color: #fafafa;
      --mdc-switch-selected-handle-color: #fafafa;
      --mdc-switch-selected-focus-handle-color: white;
      --mdc-switch-selected-hover-handle-color: white;
      --mdc-switch-selected-pressed-handle-color: white;
      --mdc-switch-disabled-unselected-handle-color: #fafafa;
      --mdc-switch-handle-elevation: var(--mdc-switch-handle-box-shadow);
      --mdc-switch-disabled-handle-elevation: var(--mdc-switch-handle-box-shadow);
    }
  }

  .mdc-switch__handle-track {
    left: 1px;
  }

  &.mat-mdc-slide-toggle-checked {
    .mdc-switch__handle-track {
      left: -1px;
    }
  }

  .mdc-switch__icons {
    display: none;
  }

  .mdc-form-field {
    > label {
      @include font-family-roboto;

      margin-bottom: 0;
      padding-left: 8px;
      font-size: var(--mdc-slide-toggle-label-font-size, 14px);
      font-weight: var(--mdc-slide-toggle-label-font-weight, 400);
      color: var(--mdc-slide-toggle-label-color, var(--grey-color-dark-2));
    }

    .mdc-switch--disabled {
      opacity: 1;
      cursor: not-allowed;

      ~ label {
        opacity: .38;
      }
    }
  }
}
