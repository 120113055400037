@import 'fonts';

button {
  transition: opacity 0.25s ease-in-out, background-color 0.25s ease-in-out, border-color 0.25s ease-in-out, color 0.25s ease-in-out;
  outline: none;

  &:disabled,
  :disabled,
  .disabled {
    cursor: not-allowed;
    opacity: 0.4;
  }
}

.button {
  position: relative;
  display: flex;
  height: 40px;
  max-height: 40px;
  align-items: center;
  justify-content: center;
  padding-top: 0;
  padding-bottom: 0;
  transition: opacity 0.25s ease-in-out, background-color 0.25s ease-in-out, border-color 0.25s ease-in-out, color 0.25s ease-in-out;

  &.tiny {
    height: 30px;
    max-height: 30px;
    padding-right: 0.5em;
    padding-left: 0.5em;
    font-size: $font-size-xxs;

    .material-icons {
      font-size: $font-size-xs;
    }
  }

  &.small {
    height: 35.8px;
    max-height: 35.8px;
    padding-right: 0.75em;
    padding-left: 0.75em;
    font-size: $font-size-xs;

    .material-icons {
      font-size: $font-size-s;
    }
  }

  &.icon {
    padding: 0 5px;
  }

  span {
    margin-right: 3px;
    margin-left: 3px;
  }

  &:disabled,
  :disabled,
  .disabled {
    cursor: not-allowed;
    opacity: 0.4;
  }

  &.x-large {
    width: 100%;
    height: 100px;
    max-height: 100px;
    border-radius: 5px;
  }

  &.no-bottom-margin {
    margin-bottom: 0;
  }
}
