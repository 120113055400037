.mat-mdc-tooltip {
  --mdc-plain-tooltip-supporting-text-size: 13px;
  --mdc-plain-tooltip-container-color: rgb(97 97 97 / 90%);

  white-space: normal;
  word-break: break-word;

  .mdc-tooltip__surface {
    padding: 6px 8px;
    line-height: 20px;
    max-width: 250px;
  }
}
