@import 'mixins/typography';

.mat-mdc-radio-button:not(.editor-radio-button) {
  --radio-default-color: var(--mdc-radio-border-color, #333);
  --radio-secondary-default-color: white;
  --radio-inner-circle-scale: 0.4;
  --radio-touch-target-size: 26px;
  --radio-size: 16px;

  border-width: 2px;
  border-color: var(--mdc-radio-button-border-color, var(--radio-default-color));

  .mdc-form-field {
    &:has(.mdc-radio__native-control:disabled) {
     &, > * {
        cursor: var(--mdc-radio-cursor, not-allowed);
      }
    }

    input:disabled {
      opacity: 0
    }

    > label {
      margin-bottom: 0;
      padding-inline: 6px;
      font-size: var(--mdc-radio-button-label-font-size, 16px);
      font-weight: var(--mdc-radio-button-label-font-weight, 400);
      color: var(--mdc-radio-button-label-color, var(--radio-default-color));
      display: inline-flex;
      align-items: center;
      line-height: normal;

      ui-kit-icon {
        margin-left: 5px;
      }
    }

    .mdc-radio {
      // base styles
      --mdc-radio-state-layer-size: var(--mdc-radio-state-layer-size-override, var(--radio-touch-target-size));
      --mdc-radio-selected-icon-color: var(--radio-color, var(--blue-color));
      --mdc-radio-unselected-icon-color: var(--radio-default-color);

      // disabled
      --mdc-radio-disabled-selected-icon-color: var(--radio-color, var(--blue-color));
      --mdc-radio-disabled-unselected-icon-color: var(--radio-default-color);

      // ripple
      --mat-mdc-radio-ripple-color: var(--radio-color, var(--blue-color));
      --mat-mdc-radio-checked-ripple-color: var(--radio-color, var(--blue-color));

      // focus
      --mdc-radio-selected-focus-icon-color: var(--radio-color, var(--blue-color));
      --mdc-radio-unselected-focus-icon-color: var(--radio-default-color);

      // hover
      --mdc-radio-selected-hover-icon-color: var(--radio-color, var(--blue-color));
      --mdc-radio-unselected-hover-icon-color: var(--radio-default-color);

      // pressed
      --mdc-radio-selected-pressed-icon-color: var(--radio-color, var(--blue-color));
      --mdc-radio-unselected-pressed-icon-color: var(--radio-default-color);

      width: var(--radio-size);
      height: var(--radio-size);

      .mat-mdc-radio-touch-target {
        width: var(--radio-touch-target-size);
        height: var(--radio-touch-target-size);
      }

      .mdc-radio__background {
        width: var(--radio-size);
        height: var(--radio-size);

        &::before {
          top: calc(-1 * (var(--mdc-radio-state-layer-size, 40px) - var(--radio-size)) / 2);
          left: calc(-1 * (var(--mdc-radio-state-layer-size, 40px) - var(--radio-size)) / 2);
        }
      }

      /**
       * ENABLED
       */
      &:has(.mdc-radio__native-control:enabled) {
        // Unchecked
        &:has(.mdc-radio__native-control:not(:checked)) {
          .mdc-radio__background {
            .mdc-radio__outer-circle {
              border-color: var(--radio-default-color);
              background-color: var(--radio-secondary-default-color);
            }
          }
        }

        // Checked
        &:has(.mdc-radio__native-control:checked) {
          .mdc-radio__background {
            .mdc-radio__outer-circle {
              border-color: var(--mdc-radio-selected-icon-color);
              background-color: var(--mdc-radio-selected-icon-color);
            }

            .mdc-radio__inner-circle {
              background-color: var(--radio-secondary-default-color);
              border-color: var(--radio-secondary-default-color);
              transform: scale(var(--radio-inner-circle-scale));
            }
          }
        }
      }

      /**
       * DISABLED
       */
      &:has(.mdc-radio__native-control:disabled) {
        // Unchecked
        &:has(.mdc-radio__native-control:not(:checked)) {
          .mdc-radio__background {
            .mdc-radio__outer-circle {
              border-color: var(--radio-default-color);
              background-color: var(--grey-color-light-3);
              opacity: 1;
            }
          }
        }

        // Checked
        &:has(.mdc-radio__native-control:checked) {
          .mdc-radio__background {
            .mdc-radio__outer-circle {
              border-color: var(--radio-default-color);
              background-color: var(--radio-default-color);
              opacity: 1;
            }

            .mdc-radio__inner-circle {
              background-color: var(--radio-secondary-default-color);
              border-color: var(--radio-secondary-default-color);
              transform: scale(var(--radio-inner-circle-scale));
              opacity: 1;
            }
          }
        }
      }

      .mdc-radio__inner-circle {
        border-width: 6px;
      }

      .mdc-radio__outer-circle {
        background-color: var(--mdc-radio-background-color, unset);
        border-width: 2px;
      }
    }
  }
}
