@import 'mixins/typography';
@import 'variables/palettes';

.ui-kit,
.ui-kit-modal-panel,
.ui-kit-page-content {
  @include font-family-roboto;

  /***********************
   * LINK
   ***********************/
  a {
    transition: color 0.15s ease-in-out, border-color 0.15s ease-in-out;

    &:active,
    &:focus,
    &:visited {
      box-shadow: none;
      color: inherit;
      text-decoration: none;
    }

    &:hover {
      box-shadow: none;
      color: var(--primary-color);
      cursor: pointer;
      text-decoration: none;
    }
  }

  /***********************
   * HEADINGS
   ***********************/
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    @include font-family-roboto;

    margin: 0;
    padding: 0 0 0.75em;
    border: none;
    color: black;
  }

  h1 {
    font-weight: 500;
    font-size: 24px;
    line-height: 28px;
  }

  h2 {
    font-family: Inter, Roboto, 'Helvetica Neue', sans-serif;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
  }

  h3 {
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;

    &.thin {
      font-weight: 400;
    }
  }

  /***********************
   * PARAGRAPH
   ***********************/
  p {
    margin: 0;
    padding: 0 0 1em;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.01em;
    color: var(--primary-text-color);
  }

  /***********************
   * PALETTES
   ***********************/
  @each $palette in $palettes {
    .ui-kit-color-#{$palette} {
      color: var(--#{$palette}-color);
    }
  }

  /***********************
   * HELPER CLASSES
   ***********************/
  .text-center {
    text-align: center;
  }

  .text-bold {
    font-weight: 500;
  }

  .text-extra-bold {
    font-weight: 700;
  }

  .text-italic {
    font-style: italic;
  }

  .text-success {
    color: var(--success-color);
  }

  .font-family-inter {
    @include font-family-inter;
  }

  .capital {
    text-transform: capitalize;
  }
}
