:root {
  // PRIMARY
  --itds-palette-primary: var(--itds-neutral-600);
  --itds-palette-primary-hover: var(--itds-neutral-700);
  --itds-palette-primary-active: var(--itds-neutral-800);
  --itds-palette-primary-light: var(--itds-neutral-150);
  --itds-palette-primary-light-hover: var(--itds-neutral-200);
  --itds-palette-primary-light-active: var(--itds-neutral-300);
  --itds-palette-primary-light-text: var(--itds-neutral-800);

  // SECONDARY
  --itds-palette-secondary: var(--itds-neutral-150);
  --itds-palette-secondary-hover: var(--itds-neutral-200);
  --itds-palette-secondary-active: var(--itds-neutral-300);
  --itds-palette-secondary-text: var(--itds-neutral-800);
  --itds-palette-secondary-hollow-text: var(--itds-neutral-800);
  --itds-palette-secondary-hollow-active-text: var(--itds-neutral-800);

  // WARNING
  --itds-palette-warning: var(--itds-yellow-200);
  --itds-palette-warning-hover: var(--itds-yellow-300);
  --itds-palette-warning-active: var(--itds-yellow-400);
  --itds-palette-warning-text: var(--itds-yellow-900);
  --itds-palette-warning-light: var(--itds-yellow-50);
  --itds-palette-warning-light-hover: var(--itds-yellow-100);
  --itds-palette-warning-light-active: var(--itds-yellow-200);

  // SUCCESS
  --itds-palette-success: var(--itds-green-400);
  --itds-palette-success-hover: var(--itds-green-500);
  --itds-palette-success-active: var(--itds-green-600);
  --itds-palette-success-light: var(--itds-green-50);
  --itds-palette-success-light-hover: var(--itds-green-100);
  --itds-palette-success-light-active: var(--itds-green-200);
  --itds-palette-success-light-text: var(--itds-green-800);

  // DANGER
  --itds-palette-danger: var(--itds-red-600);
  --itds-palette-danger-hover: var(--itds-red-700);
  --itds-palette-danger-active: var(--itds-red-800);
  --itds-palette-danger-light: var(--itds-green-50);
  --itds-palette-danger-light-hover: var(--itds-red-100);
  --itds-palette-danger-light-active: var(--itds-red-200);
  --itds-palette-danger-light-text: var(--itds-red-600);

  // INFO
  --itds-palette-info: var(--itds-bright-blue-600);
  --itds-palette-info-hover: var(--itds-bright-blue-700);
  --itds-palette-info-active: var(--itds-bright-blue-800);

  // BRAND
  --itds-palette-brand: var(--itds-brand-blue-300);
  --itds-palette-brand-hover: var(--itds-brand-blue-400);
  --itds-palette-brand-active: var(--itds-brand-blue-500);

  // ORANGE
  --itds-palette-orange: var(--itds-orange-600);
  --itds-palette-orange-hover: var(--itds-orange-700);
  --itds-palette-orange-active: var(--itds-orange-800);
  --itds-palette-orange-light: var(--itds-orange-50);
  --itds-palette-orange-light-hover: var(--itds-orange-100);
  --itds-palette-orange-light-active: var(--itds-orange-200);
  --itds-palette-orange-light-text: var(--itds-orange-800);

  // PINK
  --itds-palette-pink: var(--itds-pink-600);
  --itds-palette-pink-hover: var(--itds-pink-700);
  --itds-palette-pink-active: var(--itds-pink-800);
  --itds-palette-pink-light: var(--itds-pink-50);
  --itds-palette-pink-light-hover: var(--itds-pink-100);
  --itds-palette-pink-light-active: var(--itds-pink-200);
  --itds-palette-pink-light-text: var(--itds-pink-800);

  // PURPLE
  --itds-palette-purple: var(--itds-purple-600);
  --itds-palette-purple-hover: var(--itds-purple-700);
  --itds-palette-purple-active: var(--itds-purple-800);
  --itds-palette-purple-light: var(--itds-purple-50);
  --itds-palette-purple-light-hover: var(--itds-purple-100);
  --itds-palette-purple-light-active: var(--itds-purple-200);
  --itds-palette-purple-light-text: var(--itds-purple-800);
}
