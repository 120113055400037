@import 'customcolors';

.mat-mdc-row {
  &.row-deleted {
    background-color: $table-row-deleted;
    opacity: 0.75;

    &:hover {
      background-color: $table-row-deleted !important;
    }
  }
}

.mat-mdc-cell.mdc-data-table__cell,
.mat-mdc-header-cell.mdc-data-table__header-cell,
.mat-mdc-footer-cell.mdc-data-table__cell {
  padding: 0 5px;

  &:first-child {
    padding-left: 24px;
  }

  &:last-child {
    padding-right: 24px;
  }

  &.text-right {
    justify-content: flex-end;
  }
}

.mat-mdc-cell {
  &.no-bottom-margin p {
    margin-bottom: 0;
  }
}
