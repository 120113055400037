@import 'mixins/typography';

.font-style-action {
  @include font-family-roboto;

  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: var(--action-color);
}
