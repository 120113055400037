.legacy-form-field.mat-mdc-form-field.mat-form-field-appearance-outline {
  --mat-form-field-border-color: #505050;
  --mat-form-field-border-color-focus: var(--blue-color);
  --mat-form-field-border-thickness: 1px;
  --mat-form-field-border-radius: 0;
  --mat-form-field-padding-x: 8px;
  --mat-form-field-padding-y: 0;
  --mat-form-field-min-height: 36px;

  font-size: 0.8125rem;
  font-weight: normal;
  line-height: 1.6;
  width: 100%;
  background-color: var(--background-color);
  box-shadow: inset 0 1px 2px rgb(0 0 0 / 10%);
  transition: box-shadow 0.5s ease-in-out;

  &.mat-focused {
    box-shadow: 0 0 5px var(--mat-form-field-border-color-focus);
  }

  .mat-mdc-text-field-wrapper {
    padding: 0 var(--mat-form-field-padding-x);

    &.mdc-text-field--focused .mat-mdc-form-field-flex .mdc-notched-outline {
      &__leading, &__notch, &__trailing {
        border-color: var(--mat-form-field-border-color-focus);
      }
    }

    .mat-mdc-form-field-flex {
      .mdc-notched-outline {
        &__leading {
          border-left: var(--mat-form-field-border-thickness) solid;
          border-top-left-radius: var(--mat-form-field-border-radius);
          border-bottom-left-radius: var(--mat-form-field-border-radius);
        }

        &__trailing {
          border-right: var(--mat-form-field-border-thickness) solid;
        }

        &__leading, &__notch, &__trailing {
          border-color: var(--mat-form-field-border-color);
          border-top: var(--mat-form-field-border-thickness) solid;
          border-bottom: var(--mat-form-field-border-thickness) solid;
          border-top-right-radius: var(--mat-form-field-border-radius);
          border-bottom-right-radius: var(--mat-form-field-border-radius);
          transition: border-color 0.25s ease-in-out;
        }
      }

      .mat-mdc-form-field-infix {
        padding: var(--mat-form-field-padding-y) 0;
        min-height: var(--mat-form-field-min-height);
        display: flex;
        align-items: center;

        .mat-infix {
          flex: 1 1 auto;
          border-top: 0;
        }
      }

      .mat-mdc-form-field-icon-suffix > div > * {
        padding-right: var(--mat-form-field-padding-x);

        label {
          margin: 0;
        }
      }
    }
  }

  .mat-mdc-form-field-subscript-wrapper {
    display: none;
  }
}
