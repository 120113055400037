@import 'variables/palettes';
@import 'mixins/typography';

.cdk-overlay-pane .mdc-menu-surface.mat-mdc-autocomplete-panel.autocomplete-panel,
.cdk-overlay-pane .mdc-menu-surface.mat-mdc-select-panel.ui-kit-select {
  padding: 8px;
  margin: 5px 0;
  border-radius: 8px;
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
  border: 1px solid var(--grey-color-light-4);
  box-shadow: 0 1px 10px rgba(0 0 0 / 8%) !important;

  &.mat-mdc-select-panel.ui-kit-select:not(.disable-overhang) {
    min-width: calc(100% + 32px);
  }

  &:not(.mat-mdc-select-panel-above, .mat-mdc-autocomplete-panel-above) {
    border-top-right-radius: 8px;
    border-top-left-radius: 8px;
  }

  // Hide empty message when there are options or loader
  &:has(.mat-mdc-option.ui-kit-autocomplete-option),
  &:has(.mat-mdc-option.ui-kit-autocomplete-loader) {
    .mat-mdc-option.ui-kit-autocomplete-empty-message {
      display: none;
    }
  }

  @each $palette in $palettes {
    &.ui-kit-palette-#{$palette} {
      .mat-mdc-option.mat-mdc-option-active {
        background-color: var(--#{$palette}-color) !important;
      }
    }
  }

  .mat-mdc-option {
    @include font-family-roboto;

    margin: 2px 0;
    padding: 8px 10px;
    border-radius: 6px;
    font-size: 14px;
    font-weight: 500;
    transition: opacity 0.25s ease-in-out, background-color 0.25s ease-in-out, color 0.25s ease-in-out;
    height: 3em;
    cursor: pointer;

    .mdc-list-item__primary-text {
      color: var(--secondary-text-color);
    }

    &:hover:not(
      .mat-mdc-option-disabled,
      .mat-mdc-option-active,
      .ui-kit-autocomplete-empty-message,
      .ui-kit-autocomplete-loader
    ) {
      background-color: var(--grey-color-light-4);

      .mdc-list-item__primary-text {
        color: var(--secondary-text-color);
      }
    }

    &.mat-mdc-option-active .mdc-list-item__primary-text {
      color: white;
    }

    &.mat-mdc-option-disabled {
      opacity: 0.5;
      cursor: not-allowed;

      &:hover {
        background-color: white !important;

        .mdc-list-item__primary-text {
          color: var(--secondary-text-color);
        }
      }
    }

    &.ui-kit-autocomplete-loader,
    &.ui-kit-autocomplete-empty-message {
      cursor: initial;
    }

    &-pseudo-checkbox {
      display: none;
    }

    ui-kit-icon {
      margin-right: 5px;
    }
  }
}

.cdk-overlay-pane .mdc-menu-surface.mat-mdc-select-panel.ui-kit-select,
.mat-mdc-autocomplete-panel {
  .mat-mdc-option {
    &.has-secondary-label {
      line-height: 1.2em !important;

      .secondary-label {
        font-size: 12px;
        opacity: 0.5;
      }
    }
  }

  &.width-100-items {
    .mdc-list-item__primary-text {
      width: 100%;
    }
  }

  &.height-100-items {
    .mat-mdc-option:not(.ui-kit-autocomplete-empty-message, .ui-kit-autocomplete-loader) {
      .mdc-list-item__primary-text {
        height: 100%;
      }
    }
  }

  &.reset-padding {
    .mat-mdc-option:not(.ui-kit-autocomplete-empty-message, .ui-kit-autocomplete-loader) {
      .mdc-list-item__primary-text {
        padding: 0 !important;
      }
    }
  }
}
