/* *
 * Below defines custom color to build a theme palette from
 */
@use '@angular/material' as mat;

$it-blue: (
  50: #78e5ff,
  100: #5db3de,
  200: #3c83a7,
  300: #5db3de,
  400: #5db3de,
  500: #5db3de,
  600: #5db3de,
  700: #5db3de,
  800: #5db3de,
  900: #5db3de,
  A100: #5db3de,
  A200: #5db3de,
  A400: #5db3de,
  A700: #5db3de,
  contrast: (
    50: white,
    100: white,
    200: white,
    300: white,
    400: white,
    500: white,
    600: white,
    700: white,
    800: white,
    900: white,
    A100: white,
    A200: white,
    A400: white,
    A700: white,
  ),
);
