@import 'customcolors';

p {
  color: $primary-font-color;
}

.text-ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
