.ui-kit-button-container {
  display: flex;
  justify-content: space-between;
  gap: 10px;
  width: 100%;

  &.shrink {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }

  &.flex-end {
    justify-content: flex-end;
  }

  &.flex-start {
    justify-content: flex-start;
  }

  .left-group,
  .right-group {
    display: flex;
    gap: 10px;
  }
}
